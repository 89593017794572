<template>
  <div class="wytg">
    <HeTong ref="hetong" v-if="showHt" :currentItem="currentItem" :currentCode="currentCode" />
    <!-- AddEdit新增、编辑页面 -->
    <AddEdit v-if="showEdit" @closeDialog="closeDialog" ref="addEdit" />
    <!-- ShowDetail查看详情页面 -->
    <ShowDetail v-if="showDetail" @closeDialog2="closeDialog2" ref="showDetail" />
    <!-- 上传合同照片组件 -->
    <upLook class="hsajhjdia" v-if="UpLook" @closeDialog3="closeDialog3" ref="UpLook" />
    <div class="form-list">
      <div class="table-search">
        <h3>我要托管</h3>
        <!-- 列表搜索条件，已封装 -->
        <FormQuery ref="formquery" :data="Query" :key="keyForm" @submit="search" @reset="reset">
        </FormQuery>
        <div class="tools">
          <el-button type="success" @click="add">发布新托管</el-button>
        </div>
      </div>
      <div class="table-content">
        <table-page :data="tableData" :columns="columns" :currentPage="query.pnum" :pageSize="query.psize"
          :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange" @selectionChange="handleSelection">
          <template slot="orderType" slot-scope="scope">
            <span>{{
              scope.row.orderType == 1 ? "全程托管" : "环节托管"
              }}</span>
          </template>
          <template slot="orderState" slot-scope="scope">
            <span v-if="scope.row.orderState == '00'">待成交</span>
            <span v-if="scope.row.orderState == '01'">托管中</span>
            <span v-if="scope.row.orderState == '02'">托管结束</span>
            <span v-if="scope.row.orderState == null">待成交</span>
          </template>
          <template slot="createTime" slot-scope="scope">
            <span>{{
              scope.row.createTime ? scope.row.createTime.substr(0, 10) : ""
              }}</span>
          </template>
          <template slot="Time" slot-scope="scope">
            <span>{{
              scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
              }}~{{
              scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
              }}</span>
          </template>
          <template slot="operation" slot-scope="scope">
            <el-button type="text" size="small" @click="detailClick(scope.row)">查看</el-button>
            <el-button type="text" size="small" v-if="!scope.row.takeOverName "
              @click="editClick(scope.$index, scope.row)">修改</el-button>
            <el-button type="text" size="small" @click="delClick(scope.row.orderId)">删除</el-button>
            <el-button v-if="scope.row.takeOverName" type="text" size="small"
              @click="upLook(scope.row,1)">上传合同</el-button>
            <el-button v-if="scope.row.takeOverName && scope.row.picUrl!=null&&scope.row.picUrl!='[]'" type="text"
              size="small" @click="upLook(scope.row,0)">查看合同</el-button>
            <!--  先注释，后面要用 3.14   合同预览异常-->
            <!-- <el-button
              v-if="scope.row.takeOverName"
              type="text"
              size="small"
              @click="goout(scope.row)"
              >导出合同</el-button
            > -->
          </template>
        </table-page>
      </div>
    </div>
  </div>
</template>
<script>
  import FormQuery from "@/components/form/form-search";
  import TablePage from "@/components/table/table-page";
  import AddEdit from "./addEdit";
  import ShowDetail from "./showDetail";
  import { wytgColumn } from "../tableColumn";
  //上传合同照片组件
  import upLook from "./upContractPhotos.vue";
  import { removeDetail, getDisticntCompanyCrop } from "@/api/add";
  import { getToPage } from "@/api/projectManage";
  import { mapGetters } from "vuex";
  import HeTong from "../../../quickLink/hetong.vue";

  const Query = [
    {
      name: "orderType",
      label: "托管类型：",
      componentType: "select",
      inputType: "",
      placeholder: "请选择",
      width: "160px",
      dataOptions: [
        {
          name: "全程托管",
          value: 1,
        },
        {
          name: "环节托管",
          value: 2,
        },
      ],
    },
    {
      name: "cropCode",
      label: "种养品种：",
      componentType: "Selects",
      placeholder: "请选择",
      width: "140px",
      inputType: "",
      dataOptions: [],
    },
    {
      name: "orderState",
      label: "成交状态：",
      componentType: "select",
      placeholder: "请选择",
      width: "120px",
      dataOptions: [
        {
          name: "已成交",
          value: 1,
        },
        {
          name: "未成交",
          value: 0,
        },
      ],
    },
    {
      name: "companyName",
      label: "接管人：",
      componentType: "input",
      placeholder: "请选择",
      width: "180px",
      dataOptions: [],
    },
  ];
  export default {
    components: {
      FormQuery,
      TablePage,
      AddEdit,
      ShowDetail,
      HeTong,
      upLook,
    },
    filters: {
      name1Filter(value) {
        return value == 1 ? "全程托管" : "其它";
      },
    },
    data() {
      return {
        showDetail: false,
        zuowuArr: [],
        showEdit: false,
        loading: false,
        keyForm: 1, //下拉框为请求来的数据时监听会用到
        form: {},
        Query: Query,
        query: {
          pnum: 1,
          psize: 10,
        },
        total: 0,
        tableData: [],
        columns: wytgColumn,
        options: [
          { value: 0, label: "已成交" },
          { value: 1, label: "未成交" },
        ],
        dialogVisible: false,
        selectRow: {},
        // contractInfo: null,
        // contractArray: [],

        currentCode: 2,
        showHt: false,
        currentItem: {},
        UpLook: false,
      };
    },
    // watch: {
    //   keyForm(n, o) {
    //     console.log("keyForm", n);
    //   },
    // },
    created() {
      this.zuowu();
      // this.initRegion();
      this.getList();
    },
    computed: mapGetters(["userInfo", "identityObj"]),
    methods: {
      // 关闭弹框
      closeDialog() {
        this.showEdit = false;
      },
      dialogClose(val) {
        // 关闭弹窗
        this.dialogVisible = val;
      },
      //点击查看详情
      detailClick(val) {
        this.showDetail = true;
        this.$nextTick(() => {
          this.$refs.showDetail.init(val);
        });
      },
      // //获取接管人列表 并放置在搜索下拉框里
      // async initRegion() {
      //   let res = await getCompanyIdAll();
      //   // console.log(res.data,'这个接口是否用到了呢')
      //   let arr = [];
      //   if (res.code == 0) {
      //     res.data.forEach((item) => {
      //       arr.push({
      //         name: item.companyName,
      //         value: item.id,
      //       });
      //     });
      //     if (arr.length) {
      //       let hasRegion = setInterval(() => {
      //         this.Query[3].dataOptions = arr;
      //         this.keyForm++;
      //         clearInterval(hasRegion);
      //       }, 100);
      //     }
      //   }
      // },
      add() {
        // 显示发布的弹框
        this.showEdit = true;
      },
      //关闭详情弹框页
      closeDialog2() {
        this.showDetail = false;
      },
      handleSelection(selects) {
        console.log(selects);
      },
      //分页单页切换
      handleCurrentChange(page) {
        this.query.pnum = page;
        this.getList();
      },
      //分页总页数切换
      handleSizeChange(size) {
        this.query = {
          pnum: 1,
          psize: size,
        };
        this.getList();
      },
      //点击编辑按钮
      editClick(a, b) {
        this.showEdit = true;
        console.log(b, '查看b返回的是什么数据')
        this.$nextTick(() => {
          this.$refs.addEdit.editObj(b);
        });
      },
      //点击删除
      delClick(id) {
        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await removeDetail({ id: id });
            console.log("删除返回", res);
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            }
          })
          .catch(() => {
            // this.$message({
            //   type: "info",
            //   message: "已取消删除",
            // });
          });
      },
      // 点击搜索按钮
      search(params) {
        this.query.pnum = 1;
        // 组装条件
        this.query = {
          ...this.query,
          ...params,
          ...{ cropCode: params.cropCode.join(",") },
        };
        this.getList();
      },
      submit() { },
      // 搜索条件重置按钮
      reset() {
        this.query = {
          pnum: 1,
          psize: 10,
        };
        this.getList();
      },
      // 关闭上传合同弹框
      async closeDialog3() {
        this.UpLook = false;
        this.getList()
      },
      //获取作物列表
      async zuowu() {
        let arr = await getDisticntCompanyCrop();
        console.log(arr, "作物列表");
        arr.data.forEach((item) => {
          item.label = item.categoryName;
          item.value = item.categoryName;
        });
        let hasRegion = setInterval(() => {
          this.Query[1].dataOptions = arr.data;
          this.keyForm++;
          clearInterval(hasRegion);
        }, 100);
        this.zuowuArr = arr.data;
      },
      // 根据分页、query条件等，获取列表
      async getList() {
        this.loading = true;
        let res = await getToPage(this.query);
        // console.log(this.query,'第二次打印')
        if (!res.code) {
          this.tableData = res.data;
          this.total = res.dataNum;
          this.loading = false;
        } else {
          this.$message.error("查询失败");
          this.loading = false;
        }
      },
      // 导出合同
      async goout(currentItem) {
        this.currentItem = currentItem;
        if (!this.identityObj.identityTypeCode) {
          this.$message.info("请登录后进行操作");
          return;
        }
        if (this.currentItem.orderState === "01") {
          this.$message.info("托管中，不支持导出合同");
          return;
        }

        // if (res.code == 0) {
        this.showHt = true;
        setTimeout(() => {
          this.$refs.hetong.viewHt2();
        }, 100);
        // }
      },
      //上传合同照片
      async upLook(obj, isUp) {
        this.UpLook = true;
        this.$nextTick(() => {
          this.$refs.UpLook.Init(obj, isUp);
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .form-list {
    .table-search {
      padding: 0 0 0 30px;

      /deep/.el-button {
        padding: 10px 20px !important;
      }

      .tools {
        margin-bottom: 18px;
        margin-top: 6px;
      }
    }

    .table-content {
      padding: 0 30px;
    }
  }
</style>